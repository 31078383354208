import { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Dot,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import { InputData, UNIT } from '../interfaces/tenstilTest';
import { projectTheme } from '../styles/variables';
import { DataKey } from 'recharts/types/util/types';

// @ts-ignore
function CDot({ selectedMin, selectedMax, strokeColor, ...props }) {
  return (
    <Dot
      {...props}
      stroke={
        selectedMin?.number === props?.payload?.number ||
          selectedMax?.number === props?.payload?.number
          ? 'blue'
          : strokeColor
      }
      strokeWidth={
        selectedMin?.number === props?.payload?.number ||
          selectedMax?.number === props?.payload?.number
          ? 2
          : 1
      }
      r={
        selectedMin?.number === props?.payload?.number ||
          selectedMax?.number === props?.payload?.number
          ? 5
          : 2
      }
    />
  );
}

type LegendOnMouseEnterParams = Parameters<NonNullable<ConstructorParameters<typeof Legend>[0]['onMouseEnter']>>;
type LegendOnMouseLeaveParams = Parameters<NonNullable<ConstructorParameters<typeof Legend>[0]['onMouseLeave']>>;
type LegendOnMouseEventDataParam =
  LegendOnMouseEnterParams[0] |
  LegendOnMouseLeaveParams[0] |
  { dataKey: DataKey<string> };

export function AppChart<T extends InputData>({
  data,
  xDataKey,
  yDataKeys,
  xDataUnit,
  yDataUnit,
  selectValue,
  min,
  max,
  strokeColors,
}: {
  data: T[];
  xDataKey: keyof InputData;
  xDataUnit?: UNIT;
  yDataKeys: (keyof InputData)[];
  yDataUnit?: UNIT;
  selectValue: ((e: CategoricalChartState) => void) | undefined;
  min: number;
  max: number;
  strokeColors?: Partial<Record<keyof InputData, string>>;
}) {
  const [opacity, setOpacity] = useState<{ [k in keyof InputData]?: number; }>(
    Object.fromEntries(yDataKeys.map(dataKey => [dataKey, 1]))
  );
  const [activeLines, setActiveLines] = useState<{ [k in keyof InputData]?: boolean; }>(
    Object.fromEntries(yDataKeys.map(key => [key, true]))
  );

  const selectedMin = min > 0 ? data[min] : null;
  const selectedMax = min > 0 ? data[max] : null;

  const chartWidth = useMemo(() => {
    if (window.innerWidth > 1900) {
      return 1000;
    }

    return window.innerWidth - 200;
  }, []);

  const resetOpacity = () => {
    setOpacity(Object.fromEntries(yDataKeys.map(dataKey => [dataKey, 1])));
  };

  const handleLegendMouseEnter = (o: LegendOnMouseEventDataParam) => {
    if (typeof o.dataKey === 'string' && activeLines[o.dataKey]) {
      const opacityValues = Object.fromEntries(yDataKeys.map(dataKey => [dataKey, 0.25]));
      opacityValues[o.dataKey] = 1;
      setOpacity(opacityValues);
    }
  };

  const handleLegendMouseLeave = (o: LegendOnMouseEventDataParam) => {
    resetOpacity();
  };

  const handleLegendClick = (o: LegendOnMouseEventDataParam) => {
    if (typeof o.dataKey !== 'string') {
      return;
    }

    const activeValues = { ...activeLines, [o.dataKey]: !activeLines[o.dataKey] };
    console.log(activeValues);
    setActiveLines(activeValues);
    resetOpacity();
  };

  console.log(opacity);

  return (
    <LineChart
      width={chartWidth}
      height={500}
      data={data}
      onClick={selectValue || undefined}
    >
      <CartesianGrid strokeDasharray="1 1" />
      <XAxis dataKey={xDataKey} unit={xDataUnit} />
      <YAxis includeHidden={true} unit={yDataUnit} />
      <Legend
        layout="vertical"
        align="left"
        verticalAlign="middle"
        iconType="circle"
        formatter={
          value => <span style={{ cursor: 'pointer', margin: 4, display: 'inline-block' }}>{value}</span>
        }
        onMouseEnter={handleLegendMouseEnter}
        onMouseLeave={handleLegendMouseLeave}
        onClick={handleLegendClick}
      />
      <Tooltip />
      {yDataKeys.map(yDataKey => {
        const strokeColor = strokeColors?.[yDataKey] || projectTheme.palette.primary.main;
        return (
          <Line
            connectNulls
            type="monotone"
            dataKey={yDataKey}
            key={yDataKey}
            stroke={strokeColor}
            strokeOpacity={opacity[yDataKey] ?? 1}
            hide={!activeLines[yDataKey]}
            activeDot={{
              r: 6,
              stroke: 'red',
              fill: 'white',
            }}
            dot={<CDot strokeColor={strokeColor} selectedMin={selectedMin} selectedMax={selectedMax} />}
          />
        );
      })}
      {min > 0 ? (
        <>
          <ReferenceLine x={data[min][xDataKey]} stroke="#660227" />
          {yDataKeys.map(yDataKey => !activeLines[yDataKey] ? null : (
            <>
              <ReferenceDot
                x={data[min][xDataKey]}
                y={data[min][yDataKey]}
                stroke="#660227"
                fill="white"
                r={6}
              />
              <ReferenceLine y={data[min][yDataKey]} stroke="#660227">
                <Label position="bottom">{`Min ${data[min][yDataKey]}`}</Label>
              </ReferenceLine>
            </>
          ))}
        </>
      ) : null}
      {max > 0 ? (
        <>
          <ReferenceLine x={data[max][xDataKey]} stroke="#227d04" />
          {yDataKeys.map(yDataKey => !activeLines[yDataKey] ? null : (
            <>
              <ReferenceDot
                x={data[max][xDataKey]}
                y={data[max][yDataKey]}
                stroke="#227d04"
                fill="white"
                r={6}
              />
              <ReferenceLine y={data[max][yDataKey]} stroke="#227d04">
                <Label position="top">{`Max ${data[max][yDataKey]}`}</Label>
              </ReferenceLine>
            </>
          ))}
        </>
      ) : null}
    </LineChart>
  );
}
