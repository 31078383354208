import { TreeState } from '../interfaces/enums';
import { LanguageKeys } from './keys';

export const en: LanguageKeys = {
  common: {
    yes: 'yes',
    no: 'no',
  },
  assigningModal: {
    allOperators: 'Select all operators',
    cancelButton: 'Cancel',
    doneButton: 'Complete assignment',
    title: 'Operators',
  },
  confirmEmail: {
    heading: 'Your email has been successfully verified.',
  },
  detailProjectPage: {
    analysed: 'Analyzed',
    analysisLevel: 'Analysis Level',
    approved: 'Approved',
    approvedReport: 'Report',
    backButton: 'Back to projects',
    calculations: 'Calculations',
    countOfPhotosDetail: 'Number of detail photos',
    countOfPhotosTreeTop: 'Number of photos of the treetop',
    countOfPhotosTrunk: 'Number of photos of the trunk',
    project: 'Project',
    rejected: 'Rejected',
    site: 'Area',
    taxon: 'Taxon',
    title: 'Tree Overview',
    tree: 'Tree',
  },
  error: {
    heading: 'Ooops',
    403: 'You do not have access to this part of the application',
    noData: 'No data available',
    unknownError: 'An unexpected error occurred while communicating with the server. Please restart the application (F5) and if the error occurs repeatedly, contact support.',
  },
  finished: {
    areaName: 'Area',
    dbh: 'DBH',
    finishedOrders: 'Finished orders',
    groupName: 'Group',
    link: 'Link',
    orders: 'Orders',
    orderSentError: 'Failed to send the order.',
    orderSentSuccess: 'Order sent successfully.',
    project: 'Project',
    report: 'Report',
    safetyFactor: 'Safety factor',
    sendSelected: 'Send selected',
    sendWholeOrder: 'Send the whole order',
    tagNumber: 'Tag number',
    taxon: 'Taxon',
    treeNumber: 'Tree number',
  },
  forgotPassword: {
    choosePw: 'Choose password',
    continue: 'Continue',
    error: 'Something went wrong',
    heading: 'Set a new password',
    pwTerm1: 'At least 6 characters',
    pwTerm2: '1 uppercase letter or digit',
    pwTerms: 'Password requirements:',
    successfull: 'Password was successfully changed',
  },
  loginPage: {
    button: 'Log in',
    error: {
      password: 'Enter password',
      userName: 'Enter username',
      wrong: 'You have entered an incorrect username or password',
    },
    password: 'Password',
    user: 'User',
  },
  notifications: {
    deleteAll: 'Delete all',
    Failed: 'Tree calculation failed.',
    Finished: 'Tree calculation is finished',
    noNotifications: 'No notifications',
    Ordered: 'New tree is waiting for processing',
    readAll: 'All read',
    title: 'Notifications',
  },
  rejectNotice: {
    subtext: 'Reason for rejection:',
    title: 'Analysis failed calculations or was rejected by the Admin.',
  },
  summaryOfOperators: {
    analysisOfRoad: 'Road',
    analysisOfTreeTop: 'Top Analysis',
    analysisOfTrunk: 'Trunk Analysis',
    countOfDoneTrees: 'Number of trees',
    name: 'Operator',
    switch: 'Enabled',
  },
  summaryOfProjects: {
    buttonAssign: 'Assign',
    countOfPhotos: 'Number of photos',
    countOfTrees: 'Number of trees',
    operator: 'Operator',
    project: 'Project',
    stateAnalysisOfRoad: 'Road',
    stateAnalysisOfTreeTop: 'Top Analysis',
    stateAnalysisOfTrunk: 'Trunk Analysis',
    stateOfProject: 'Status',
  },
  summaryPage: {
    headerTitle: 'Tree Overview',
  },
  tomograms: {
    downloadImage: 'Download image',
    removeImgae: 'Remove image',
    showImage: 'Show image',
    showNote: 'Show note',
    uploadImage: 'Upload image',
  },
  tomogramsPage: {
    header: {
      cuts: 'Cuts',
      generateCuts: 'Generate Cuts',
    },
    inputs: {
      scaleImage: 'Image scale',
      scaleReal: 'Real scale',
    },
  },
  treeDetailPage: {
    backBtn: 'Back to tree overview',
    buttons: {
      back: 'Back',
      reset: 'Reset',
      reset_active_option: 'Reset selected parts',
    },
    calculations: {
      accept: 'Accept',
      cancel: 'Cancel',
      confirm: 'Confirm',
      created: 'Created',
      downloadPDF: 'Download PDF',
      goToStl: 'Download 3D Model',
      noReport: 'No processed calculations',
      noStatus: 'Pending confirmation',
      reject: 'Reject',
      rejectedMessage: 'Message to the customer',
      rejectedReason: 'Reason for rejection',
      reportTitle: 'Processed report no.',
      sendMessage: 'Yes and confirm',
      sendToCustomer: 'Send to customer',
      sendToCustomerConfirmationDialogText1: 'Do you really want to send this rejected report to the customer?',
      sendToCustomerConfirmationDialogText2: 'Enter a message here that will be displayed to the customer.',
      sendToCustomerConfirmationDialogTitle: 'Send Rejected Report',
      status: 'Status',
      title: 'Calculations',
    },
    formAlert: {
      description: 'Please modify the field',
      message: 'Error in the form',
      saved: 'Saved',
      savedInfo: 'Analysis successfully saved.',
      saveError: 'Error uploading to server',
      serverFailure: 'Server failed.',
      serverForbidden: 'Insufficient user permissions to perform analysis.',
    },
    formAlerts: {
      doneError: 'Failed to submit analysis for approval.',
      doneErrorAdmin: 'Failed to approve the analysis.',
      doneSuccess: 'Analysis is ready for approval.',
      doneSuccessAdmin: 'Analysis successfully approved.',
      refuseError: 'Failed to refuse the analysis.',
      refuseSuccess: 'Analysis successfully refused.',
    },
    header: {
      project: 'Project',
      title: 'Tree Detail no.',
    },
    inputs: {
      analysisLevel: 'Analysis level',
      announcement: 'Report',
      condition: 'Overall condition',
      dbh: {
        calculated: {
          add: 'Add calculated DBH',
          errorMsg: 'This field is required.',
          label: 'Calculated DBH',
          remove: 'Remove DBH',
        },
        treeScanner: {
          add: 'Add calculated DBH',
          label: 'DBH from TreeScanner',
          remove: 'Remove DBH',
        },
      },
      diameter: {
        helperText: 'After analyzing the tree',
        label: 'Diameter (cm)',
      },
      formButtons: {
        done: 'Done',
        refuse: 'Refuse',
        save: 'Save',
      },
      height: {
        helperText: 'From mobile application',
        label: 'Tree height from TreeScanner (m)',
      },
      incorrectNumber: 'Incorrect number',
      incorrectValue: 'Incorrect value',
      mistletoe: 'Mistletoe',
      notCalculated: 'Not calculated',
      profile: {
        city: 'City',
        countryside: 'Country',
        label: 'Profile',
        outskirts: 'Outskirts',
        village: 'Village',
      },
      protectionFactor: {
        label: 'Protection factor',
        alley: 'Alley',
        forestStand: 'Forest stand',
        solitaire: 'Solitaire',
      },
      searchTaxon: 'Search Taxon',
      selectTaxon: 'Please select a taxon!',
      taxon: 'Taxon',
      tomogramType: 'Tomogram type',
      treeTopHeight: {
        helperText: 'After analyzing the tree',
        label: 'Height of tree top (m)',
      },
    },
    mobileHints: {
      avgValue: 'Average value',
      cmAbbr: 'cm',
      fromApp: 'Value from mobile app',
      photo: 'Value from photo no.',
      scale: 'Original scale',
    },
    modals: {
      body: {
        hintBase: 'Click on the center of the trunk where it meets the ground.',
        hintCrown: 'Click and mark three points. The beginning of the crown and its width with two points at its end.',
        hintScale: 'Click on the beginning of the altitude scale. Adjust its size by moving the end points.',
        hintTrunk: 'Click and mark points on the trunk.',
        markBase: 'Mark base',
        markCenterPoint: 'Mark the boundary between the trunk and the crown',
        markCrown: 'Mark crown',
        markRange: 'Mark crown range',
        markScale: 'Mark scale',
        markTopPoint: 'Mark top point of the tree',
        markTrunk: 'Mark trunk',
        title: 'Tree Detail',
        type: 'Trunk',
        typeCrown: 'Crown',
      },
      cancel: 'Cancel',
      error: 'Error',
      hint: 'Help for',
      nowEdit: 'Currently editing',
      recalculate: 'Recalculate',
      rejection: {
        cancel: 'Cancel',
        headline: 'Please provide a reason for rejection',
        hint: 'Please fill in the reason!',
        placeholder: 'Provide a reason',
        refuse: 'Refuse',
        title: 'Tree Rejection',
      },
      save: 'Save',
      saved: 'Saved',
      saveError: 'Tree analysis was not saved correctly, please try again.',
      saveSucc: 'Analysis has been saved.',
      scale: 'Scale',
      tooltip: 'Generates a grid and objects based on created points.',
      zoom: 'Zoom',
    },
    noPhotos: 'No photos',
    orders: {
      errorMsg: 'Order was not accepted',
      successMsg: 'Order was accepted',
    },
    overview: 'Overview',
    photoEditing: {
      deleteImage: 'Do you really want to delete the photo?',
      deleteImageNo: 'No',
      deleteImageYes: 'Yes',
      deletePhoto: 'Delete photo',
      form: {
        deleteSucc: 'The photo has been deleted',
        description: 'Description',
        downloadTrunkScan: 'Download trunk scan',
        photoNumber: 'Photo number',
        save: 'Save',
        saveError: 'Failed to save the photo',
        saveSucc: 'Photo saved',
        uploadTrunkScan: 'Upload edited trunk scan',
        uploadTrunkScanImage: 'Upload trunk scan image',
      },
      imageCopy: 'Copy image:',
      noActivePhoto: 'Select a photo to edit',
      title: 'Photo Editing',
      trunk: 'Trunk',
      whole: 'Whole tree',
    },
    photoEditSuccess: 'Photo successfully edited',
    photoError: 'Failed to edit the photo',
    photoSuccess: 'Photo successfully saved',
    site: 'Area',
    tensilTest: {
      anchorageHeight: 'Anchorage height',
      bendingMomentAtBase: 'Bending moment - base of the trunk',
      bendingMomentAtMeasure: 'Bending moment - measurement position',
      calculate: 'Calculate',
      cleanDiameter: 'Clean trunk diameter',
      criticalBendingMoment: 'Critical bending moment',
      crossSectionModule: 'Cross-sectional module',
      crownExcentricity: 'Crown eccentricity',
      deformation: 'Deformation',
      dynamometer: 'Dynamometer',
      E: 'E',
      force: 'Force',
      inclination: 'Inclination',
      ktDistance: 'Distance to kT',
      measurementPosition: 'Measurement position',
      momentOfInertia: 'Moment of inertia',
      noData: 'No tensile tests found',
      precalculate: 'Pre-calculate',
      pull: 'Pull',
      push: 'Push',
      relativeDeformation: 'Relative deformation',
      resistanceToDislocation: 'Resistance to uprooting',
      resistanceToFracture: 'Resistance to fracture',
      resultFor: 'Result values for: ',
      resultValuesOfMeasurement: 'Measurement result values',
      ropeAngle: 'Rope angle',
      safeCoef: 'Safety coefficient',
      safetyFactor: 'Flexural strength',
      saveChanges: 'Save changes',
      saveError: 'Failed to upload file',
      saveSuccess: 'Tensile tests successfully uploaded',
      saving: 'Saving tensile test calculations',
      shareOfWindLoad: 'Share of wind load',
      shearStrength: 'Shear strength',
      shearStress: 'Shear stress',
      showRawData: 'Show raw data',
      substitutionForce: 'Resulting force acting on the tree',
      title: 'Tensile Test Data',
      trunkAvg1: 'Trunk diameter 2',
      trunkAvg2: 'Trunk diameter 1',
      uploadData: 'Upload data',
      variableDeformation: 'Strain ratio',
    },
    tomograms: {
      deleteImage: 'Delete image',
      downloadImage: 'Download image',
      noData: 'No tomograms found',
      showImage: 'Show image',
      showNote: 'Show note',
      title: 'Tomograms',
      uploadImage: 'Upload image',
    },
    treeTopAnalysis: 'Top Analysis',
    treeTopPart: {
      treeTop: 'Trunk',
    },
    trunkAnalysis: {
      height: 'Trunk scan height',
      save: 'Save',
      title: 'Trunk Analysis',
      trunkScan: 'Trunk scan',
    },
    trunkPart: {
      wholeTree: 'Whole tree',
    },
    trunkScanError: 'Failed to upload new trunk scan.',
    trunkScanSuccess: 'Trunk scan successfully uploaded',
  },
  treeState: {
    [TreeState.Active]: 'Active',
    [TreeState.Approved]: 'Approved',
    [TreeState.NonActive]: 'Inactive',
  },
};
