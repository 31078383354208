import {
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { FC } from 'react';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';

type TableObjectItemProps = {
  name: string;
  25: number;
  50: number;
  75: number;
  100: number;
  unit: string;
  precision: number;
} | null;

type TensilTestsPrecalculateTableProps = {
  name: string;
  tableObjects: TableObjectItemProps[];
};

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);

const zeroValueComponent = (
  <Typography style={{ fontSize: '0.875rem', color: 'red' }}>0</Typography>
);

export const TensilTestPrecalculateTable: FC<
  TensilTestsPrecalculateTableProps
> = ({ name, tableObjects }) => {
  const activeLang = useCurrentLanguage();

  return (
    <Grid item className={css(styles.tensilTestCard)}>
      <Grid container style={{ padding: 5, gap: 5 }}>
        <Typography
          style={{
            fontWeight: 700,
          }}
        >
          {activeLang.treeDetailPage.tensilTest.resultFor}
        </Typography>
        <Grid>
          <Badge
            style={{
              backgroundColor: getMeterBgColor(name),
            }}
            className={css(dynamicStyles(getMeterBgColor(name)).badge)}
          >
            {name}
          </Badge>
        </Grid>
      </Grid>
      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">
              <Typography style={{ fontWeight: 700 }}>25%</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontWeight: 700 }}>50%</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontWeight: 700 }}>75%</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontWeight: 700 }}>MAX</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableObjects.map((row: TableObjectItemProps | null) => {
            const { name: rowName, precision, unit } = row ?? {};
            const quarter = row?.[25];
            const half = row?.[50];
            const threeQuarters = row?.[75];
            const max = row?.[100];

            return (
              <StyledTableRow key={name}>
                <TableCell style={{ padding: 5 }} component="th" scope="row">
                  {rowName}
                </TableCell>
                <TableCell align="center" className={css(styles.unitCell)} data-unit={unit}>
                  {quarter?.toFixed(precision)}
                </TableCell>
                <TableCell align="center" className={css(styles.unitCell)} data-unit={unit}>
                  {half?.toFixed(precision) ?? zeroValueComponent}
                </TableCell>
                <TableCell align="center" className={css(styles.unitCell)} data-unit={unit}>
                  {threeQuarters?.toFixed(precision) ?? zeroValueComponent}
                </TableCell>
                <TableCell align="center" className={css(styles.unitCell)} data-unit={unit}>
                  {max?.toFixed(precision) ?? zeroValueComponent}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};
