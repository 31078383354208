import { Grid } from '@material-ui/core';
import { css } from 'aphrodite';
import { FC } from 'react';
import { useTensilTests } from '../../../../contexts/TensilTestsContext';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { GraphAccordions } from './GraphAccordions';
import { TensilTestData } from './TensilTestData';
import { TensilTestForm } from './TensilTestForm';
import { styles } from './TensilTests.styles';
import { TensilTestPrecalculateTables } from './TensilTestsPrecalculateTables';
import { TensilTestStillTable } from './TensilTestStillTable';

type TensilTestProps = {};

const mainBlocksPadding = 20;

export const TensilTestBody: FC<TensilTestProps> = () => {
  const activeLang = useCurrentLanguage();
  const { calculations, precalculations } = useTensilTests();

  if (window.innerWidth < 1900) {
    return (
      <Grid
        container
        className={css(styles.bodyContainer)}
        justifyContent="space-between"
        style={{ flexDirection: 'column', maxWidth: 1900 }}
      >
        <Grid item md="auto" style={{ paddingBottom: mainBlocksPadding }}>
          <GraphAccordions />
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-evenly">
            <Grid item md={9}>
              <Grid container style={{ flexDirection: 'column', gap: 10 }}>
                <Grid
                  item
                  className={css(styles.tensilTestCard)}
                  style={{ width: '100%' }}
                >
                  <Grid container style={{ flexDirection: 'row' }}>
                    <TensilTestForm />
                  </Grid>
                </Grid>
                <Grid item>
                  {precalculations && <TensilTestPrecalculateTables />}
                </Grid>
                <Grid item>
                  {calculations && (
                    <TensilTestStillTable
                      calculations={calculations}
                      title={activeLang.treeDetailPage.tensilTest.resultValuesOfMeasurement}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              style={{ flexDirection: 'column', width: 275, height: 680, position: 'sticky', top: mainBlocksPadding }}
              className={css(styles.tensilTestCard)}
            >
              <TensilTestData />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        className={css(styles.bodyContainer)}
        justifyContent="space-between"
        style={{ flexDirection: 'row' }}
      >
        <Grid item lg="auto">
          <GraphAccordions />
        </Grid>
        <Grid item lg={3}>
          <Grid container style={{ gap: 5 }}>
            <Grid
              item
              className={css(styles.tensilTestCard)}
              style={{ width: '100%' }}
            >
              <TensilTestForm />
            </Grid>
            <Grid>
              {calculations && (
                <TensilTestStillTable
                  calculations={calculations}
                  title={activeLang.treeDetailPage.tensilTest.resultValuesOfMeasurement}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            style={{ flexDirection: 'column', width: 275 }}
            className={css(styles.tensilTestCard)}
          >
            <TensilTestData />
          </Grid>
        </Grid>
      </Grid>
      {precalculations && (
        <Grid style={{ marginTop: 20 }}>
          <TensilTestPrecalculateTables />
        </Grid>
      )}
    </>
  );
};
