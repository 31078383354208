import { LoadingOutlined } from '@ant-design/icons';
import { Grid, makeStyles } from '@material-ui/core';
import { FunctionsOutlined, SaveOutlined } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTensilTests } from '../../../../contexts/TensilTestsContext';
import { colors } from '../../../../styles/colors';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import AppButton from '../Form/AppButton/AppButton';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: colors.greenPrimary,
        color: 'white',
      },
    },
  },
}));

type TensilTestHeaderProps = {};
export const TensilTestHeader: FC<TensilTestHeaderProps> = () => {
  const activeLang = useCurrentLanguage();
  const classes = useStyles();
  const { handleSubmit } = useFormContext();
  const {
    handlePaginationChange: onPaginationChange,
    tensilTests,
    activeTensilTest,
    calculateDirection,
    saveDirection,
    precalculateDirection,
    restCalculation: { isLoading: isCalculationLoading },
    calculations,
    restPrecalculation: { isLoading: isPrecalculationLoading },
    restSaveCalc: { isLoading: isSaveCalculationLoading },
    setSelectingValue,
    selectingValue,
    min,
    max,
  } = useTensilTests();
  const isLoading =
    isCalculationLoading || isPrecalculationLoading || isSaveCalculationLoading;
  const handlePaginationChange = (_: ChangeEvent<unknown>, page: number) => {
    onPaginationChange(page);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Pagination
                boundaryCount={4}
                defaultPage={1}
                count={tensilTests?.length}
                variant="outlined"
                shape="rounded"
                hideNextButton
                hidePrevButton
                onChange={handlePaginationChange}
                classes={{
                  root: classes.ul,
                }}
              />
            </Grid>

            <Grid item style={{ paddingLeft: 4 }}>
              <AppButton variant="outlined" href={activeTensilTest.inputFileUrl} target="_blank">
                {activeLang.treeDetailPage.tensilTest.showRawData}
              </AppButton>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: 10 }}>
            <Grid>
              <label>Vyberte kterou hodnotu na grafu vybíráte</label>
            </Grid>
            <AppButton
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              variant={'contained'}
              color="primary"
              onClick={() => setSelectingValue('min')}
              disabled={selectingValue === 'min' || min <= 0 || max <= 0}
            >
              Min
            </AppButton>
            <AppButton
              style={{ borderRadius: 0 }}
              variant={'contained'}
              color="primary"
              onClick={() => setSelectingValue('max')}
              disabled={selectingValue === 'max' || min <= 0 || max <= 0}
            >
              Max
            </AppButton>
            <AppButton
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              variant={'contained'}
              color="primary"
              onClick={() => setSelectingValue('auto')}
              disabled={selectingValue === 'auto'}
            >
              Auto
            </AppButton>
            <Grid>
              <label>Vybíráte hodnotu {selectingValue}</label>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <AppButton
            onClick={handleSubmit(precalculateDirection)}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <LoadingOutlined />}
          >
            {activeLang.treeDetailPage.tensilTest.precalculate}
          </AppButton>
          <AppButton
            style={{ marginLeft: 10 }}
            onClick={handleSubmit(calculateDirection)}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading ? <LoadingOutlined /> : <FunctionsOutlined />}
          >
            {activeLang.treeDetailPage.tensilTest.calculate}
          </AppButton>

          <AppButton
            style={{ marginLeft: 10 }}
            onClick={handleSubmit(saveDirection)}
            variant="contained"
            color="primary"
            disabled={isLoading || !calculations}
            startIcon={isLoading ? <LoadingOutlined /> : <SaveOutlined />}
          >
            {activeLang.treeDetailPage.tensilTest.saveChanges}
          </AppButton>
        </Grid>
      </Grid>
    </>
  );
};
