import { useTensilTests } from '../../../../contexts/TensilTestsContext';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { TensilTestPrecalculateTable } from './TensilTestsPrecalculateTable';
import { CALCULATIONS, CALCULATION_ID } from '../../../../interfaces/tenstilTest';

const CALCULATIONS_IDS = Object.keys(CALCULATIONS) as CALCULATION_ID[];

export const TensilTestPrecalculateTables = () => {
  const activeLang = useCurrentLanguage();
  const { precalculations } = useTensilTests();

  const tableItemsE1 = CALCULATIONS_IDS.map(calculationId => {
    if (precalculations?.[0].E1) {
      const { unit, precision } = CALCULATIONS[calculationId];
      return {
        name: activeLang.treeDetailPage.tensilTest[calculationId],
        25: precalculations[0]?.E1[calculationId],
        50: precalculations[1]?.E1[calculationId],
        75: precalculations[2]?.E1[calculationId],
        100: precalculations[3]?.E1[calculationId],
        precision,
        unit,
      };
    }
    return null;
  });

  const tableItemsE2 = CALCULATIONS_IDS.map(calculationId => {
    if (precalculations?.[0].E2) {
      const { unit, precision } = CALCULATIONS[calculationId];
      return {
        name: activeLang.treeDetailPage.tensilTest[calculationId],
        25: precalculations[0]?.E2[calculationId],
        50: precalculations[1]?.E2[calculationId],
        75: precalculations[2]?.E2[calculationId],
        100: precalculations[3]?.E2[calculationId],
        precision,
        unit,
      };
    }
    return null;
  });

  return (
    <>
      <TensilTestPrecalculateTable name="E1" tableObjects={tableItemsE1} />
      <TensilTestPrecalculateTable name="E2" tableObjects={tableItemsE2} />
    </>
  );
};
