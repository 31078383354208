import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { requiredRule } from '../../../../helpers/formHelpers';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { AppTensilTestsTaxonSelect } from '../../../AppTensilTestsTaxonSelect';
import AppInput from '../Form/AppInput/AppInput';
import { TensilTestSafetyCoefTable } from './TensilTestSafetyCoefTable';
import { TensilTestDirectionData } from '../../../../interfaces/tenstilTest';

type TensilTestFormProps = {};

export const TensilTestForm: FC<TensilTestFormProps> = () => {
  const activeLang = useCurrentLanguage();

  return (
    <>
      <Grid item lg={window.innerWidth < 1900 ? 6 : undefined}>
        <Grid
          container
          style={{
            padding: '12px 16px',
          }}
        >
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="anchorageHeight"
            unit={TensilTestDirectionData.anchorageHeight.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.anchorageHeight,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="ropeAngle"
            unit={TensilTestDirectionData.ropeAngle.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.ropeAngle,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="ktDistance"
            unit={TensilTestDirectionData.ktDistance.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.ktDistance,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppTensilTestsTaxonSelect rules={{ ...requiredRule }} showMessage />
        </Grid>
      </Grid>
      <Grid item lg={window.innerWidth < 1900 ? 6 : undefined} style={{ width: '100%', padding: '10px 0' }}>
        <Grid container>
          <Typography style={{ fontSize: 14, fontWeight: 700, padding: '16px', margin: 3 }}>
            {activeLang.treeDetailPage.tensilTest.safeCoef}
          </Typography>
          <TensilTestSafetyCoefTable />
        </Grid>
      </Grid>
    </>
  );
};
