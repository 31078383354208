import {
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';
import { CALCULATIONS } from '../../../../interfaces/tenstilTest';

type TableObjectItemProps = {
  name: string;
  E1?: number;
  E2?: number;
};

const tableItemsIds = [
  'measurementPosition',
  'bendingMomentAtBase',
  'bendingMomentAtMeasure',
  'criticalBendingMoment',
  'resistanceToFracture',
  'resistanceToDislocation',
  'relativeDeformation',
  'safetyFactor',
] as const;

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);

export const TensilTestStillTable = ({ calculations, title }) => {
  const activeLang = useCurrentLanguage();

  const tableItems = tableItemsIds.map(tableItemId => {
    return {
      name: tableItemId,
      E1: calculations?.E1[tableItemId],
      E2: calculations?.E2[tableItemId],
    };
  });

  if (!calculations) {
    return null;
  }

  return (
    <Grid container className={css(styles.tensilTestCard)}>
      <Typography style={{ fontSize: 14, fontWeight: 700, padding: '16px', paddingBottom: '4px', margin: 3 }}>
        {title}
      </Typography>

      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />
            {Object.keys(tableItems[0]).map(key => {
                return (key !== 'name') ? (
                  <TableCell key={key} align="center" style={{ padding: 5, minWidth: 110 }}>
                    <Badge
                      style={{
                        backgroundColor: getMeterBgColor(key),
                      }}
                      className={css(
                        key === 'Force'
                          ? styles.force
                          : dynamicStyles(getMeterBgColor(key)).badge,
                      )}
                    >
                      {key}
                    </Badge>
                  </TableCell>
                ) : null;
              }
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {tableItems.map((row: TableObjectItemProps) => {
            const { unit, precision } = CALCULATIONS[row.name as keyof typeof CALCULATIONS];

            return (
              <StyledTableRow key={row.name}>
                <TableCell style={{ padding: 5 }} component="th" scope="row">
                  {activeLang.treeDetailPage.tensilTest[row.name]}
                </TableCell>
                <TableCell className={css(styles.unitCell)} data-unit={unit}>
                  {row.E1?.toFixed(precision)}
                </TableCell>
                <TableCell className={css(styles.unitCell)} data-unit={unit}>
                  {row.E2?.toFixed(precision)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};
