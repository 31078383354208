import { Badge, Button, ButtonGroup, Grid } from '@material-ui/core';
import { css } from 'aphrodite';
import { FC } from 'react';
import {
  BallastHeightLevel,
  BallastHeightLevelType,
  ElastomerTagsConfig,
} from '../../../../interfaces/tenstilTest';
import { colors } from '../../../../styles/colors';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { styles } from './TensilTests.styles';

export type StateProps = {
  data: BallastHeightLevel;
};

type TensilTestDirectionLevelsProps = StateProps;

export const TensilTestElastomerRow: FC<TensilTestDirectionLevelsProps> = ({
  data,
}) => {
  const activeLang = useCurrentLanguage();

  const isPull = data?.type !== BallastHeightLevelType.PUSH;

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ position: 'relative', paddingBottom: 5 }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: 1,
          backgroundColor: colors.grey,
        }}
      />
      <Grid item>
        <ButtonGroup>
          <Button
            variant="outlined"
            size="small"
            disabled
            style={{ height: 30 }}
            className={css(isPull ? styles.activeButton : styles.button)}
          >
            {activeLang.treeDetailPage.tensilTest.pull}
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled
            style={{ height: 30 }}
            className={css(!isPull ? styles.activeButton : styles.button)}
          >
            {activeLang.treeDetailPage.tensilTest.push}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item style={{ height: 30, zIndex: 1 }}>
        <Grid container alignItems="center" style={{ gap: 15 }}>
          <input
            value={`${data.height ? data.height.toString() : ''} cm`}
            className={css(styles.input)}
            disabled
            readOnly
          />
          <Badge
            style={{
              backgroundColor: data?.elestomer?.color
                ? `${data?.elestomer?.color}`
                : 'white',
            }}
            className={css(styles.elastometer)}
          >
            {data?.elestomer?.name
              ? ElastomerTagsConfig[data.elestomer?.name].name
              : ''}
          </Badge>
        </Grid>
      </Grid>
    </Grid>
  );
};
