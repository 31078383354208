// expects string key of table header E1, I1, I2 ets.. and converts it to inclino values
export const getSelectedInclino = (
  key: string,
): ('inclino80x' | 'inclino80y' | 'inclino81x' | 'inclino81y') | null => {
  if (!key.startsWith('I')) {
    return null;
  }

  const inclinoNumber = key.replace('I', '');

  switch (inclinoNumber) {
    case '1x':
      return 'inclino80x';
    case '1y':
      return 'inclino80y';
    case '2x':
      return 'inclino81x';
    case '2y':
      return 'inclino81y';
    default:
      return null;
  }
};

export const getSelectedInclinoShort = (
  inclino: 'inclino80x' | 'inclino80y' | 'inclino81x' | 'inclino81y',
): ('I1x' | 'I1y' | 'I2x' | 'I2y') | null => {
  switch (inclino) {
    case 'inclino80x':
      return 'I1x';
    case 'inclino80y':
      return 'I1y';
    case 'inclino81x':
      return 'I2x';
    case 'inclino81y':
      return 'I2y';
    default:
      return null;
  }
};

export const findClosestValue = (arr: number[], selected: number) => {
  if (arr.every(item => item === null || item === 0)) {
    return null;
  }

  if (arr[selected] !== null && arr[selected] !== 0) {
    return arr[selected];
  }

  const getClosest = (
    array: (number | undefined | null)[],
    selectedIndex: number,
    originalArray: (number | undefined | null)[] = array,
    size: number = 1,
    left: number = -1,
    right: number = -1,
  ) => {
    if (size === 1) {
      return getClosest(
        [
          originalArray[selectedIndex - 1],
          array[selectedIndex],
          originalArray[selectedIndex + 1],
        ],
        selectedIndex,
        originalArray,
        size + 2,
        selectedIndex - 1,
        selectedIndex + 1,
      );
    }

    if (array.some(item => item !== null && item !== undefined && item !== 0)) {
      return array.find(
        item => item !== null && item !== undefined && item !== 0,
      );
    }

    return getClosest(
      [originalArray[left - 1], ...array, originalArray[right + 1]],
      selectedIndex,
      originalArray,
      size + 2,
      left - 1,
      right + 1,
    );
  };

  return getClosest(arr, selected);
};
