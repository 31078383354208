import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { FC } from 'react';
import { useTensilTests } from '../../../../contexts/TensilTestsContext';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { AppChart } from '../../../AppChart';
import { getMeterBgColor } from './TensilTests.styles';
import { TensilTestInputs } from '../../../../interfaces/tenstilTest';

type GraphAccordionsProps = {};

export const GraphAccordions: FC<GraphAccordionsProps> = () => {
  const activeLang = useCurrentLanguage();

  const {
    min,
    max,
    activeTensilTest: tensilTest,
    selectValue,
  } = useTensilTests();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {activeLang.treeDetailPage.tensilTest.deformation}
        </AccordionSummary>
        <AccordionDetails>
          <Grid style={{ width: '100%' }}>
            <AppChart
              data={tensilTest.data.inputData}
              xDataKey="time"
              yDataKeys={['elasto90', 'elasto91']}
              yDataUnit={TensilTestInputs.elasto90.unit}
              strokeColors={{ elasto90: '#2F80ED', elasto91: '#F2994A' }}
              selectValue={selectValue}
              min={min}
              max={max}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {activeLang.treeDetailPage.tensilTest.inclination}
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <AppChart
              data={tensilTest.data.inputData}
              xDataKey="time"
              yDataKeys={['inclino80x', 'inclino80y', 'inclino81x', 'inclino81y']}
              yDataUnit={TensilTestInputs.inclino80x.unit}
              strokeColors={{
                inclino80x: getMeterBgColor('I1x'),
                inclino80y: getMeterBgColor('I1y'),
                inclino81x: getMeterBgColor('I2x'),
                inclino81y: getMeterBgColor('I2y'),
              }}
              selectValue={selectValue}
              min={min}
              max={max}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {activeLang.treeDetailPage.tensilTest.force}
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <AppChart
              data={tensilTest.data.inputData}
              xDataKey="time"
              yDataKeys={['force100']}
              yDataUnit={TensilTestInputs.force100.unit}
              selectValue={selectValue}
              min={min}
              max={max}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
