import { StyleSheet } from 'aphrodite';
import { colors } from '../../../../styles/colors';

export const getMeterBgColor = (name: string): string => {
  switch (name) {
    case 'Force':
      return colors.tensilTest.force;
    case 'E1':
    case 'I1x':
    case 'I1y':
      return colors.tensilTest.meter1;
    case 'E2':
    case 'I2x':
    case 'I2y':
      return colors.tensilTest.meter2;
    case 'E3':
    case 'I3x':
    case 'I3y':
      return colors.tensilTest.meter3;
    case 'E4':
    case 'I4x':
    case 'I4y':
      return colors.tensilTest.meter4;
    default:
      return colors.white;
  }
};

export const dynamicStyles = (color: string) =>
  StyleSheet.create({
    badge: {
      color: colors.white,
      backgroundColor: color,
      margin: '0 auto',
      padding: '0 3px',
      width: '22px',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      fontSize: '12px',
      textAlign: 'center',
    },
  });

export const styles = StyleSheet.create({
  bodyContainer: {
    marginTop: 16,
  },
  unitCell: {
    ':not(:empty)': {
      textAlign: 'right',
      '::after': {
        content: 'attr(data-unit)',
        display: 'inline-block',
        width: '50%',
        textAlign: 'left',
        paddingLeft: '0.5ex',
      },
    },
    ':empty': {
      textAlign: 'center',
      fontSize: '0.875rem',
      color: 'red',
      '::before': {
        content: '"0"',
      }
    }
  },
  tensilTestCard: {
    borderRadius: 4,
    border: `1px solid ${colors.grey}`,
  },
  activeButton: {
    color: 'white',
    backgroundColor: colors.greenPrimary,
  },
  button: {
    color: colors.greenPrimary,
    backgroundColor: 'white',
    ':disabled': {
      color: colors.greenPrimary,
    },
  },
  input: {
    width: '65px',
    textAlign: 'right',
    height: '100%',
    borderRadius: '5px',
    padding: '5px',
    border: `1px solid #CACACA`,
    color: '#666666',
    backgroundColor: 'white',
  },

  elastometer: {
    border: `1px solid #CACACA`,
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '100%',
    '::before': {
      content: '""',
      display: 'block',
      height: '1px',
      right: '100%',
      position: 'absolute',
      width: '200px',
      zIndex: 1,
    },
  },

  force: {
    backgroundcolor: colors.tensilTest.force,
    color: colors.white,
    padding: '0 5px',
    borderRadius: 4,
  },

  selectedCell: {
    width: '90%',
    backgroundColor: '#2F80ED',
    borderRadius: 8,
    color: 'white',
    cursor: 'pointer',
  },
});
