import {
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { useCallback } from 'react';
import { useTensilTests } from '../../../../contexts/TensilTestsContext';
import {
  getSelectedInclino,
  getSelectedInclinoShort,
} from '../../../../helpers/tensilTestsHelpers';
import { SelectedState, TensilTestInputs } from '../../../../interfaces/tenstilTest';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';
export enum TableItemName {
  TWENTY_FIVE = '25%',
  FIFTY = '50%',
  SEVENTY_FIVE = '75%',
  MAX = 'MAX',
  PROTOCOL = 'PROTOCOL',
}

type TableObjectItemProps = {
  name: TableItemName;
  Force?: number;
  E1?: number;
  E2?: number;
  I1x?: number;
  I1y?: number;
  I2x?: number;
  I2y?: number;
  I3x?: number;
  I3y?: number;
  I4x?: number;
  I4y?: number;
};

const tableObject: TableObjectItemProps[] = [
  {
    name: TableItemName.TWENTY_FIVE,
    Force: 5,
    E1: 26,
    E2: 18,
    I1x: 21,
    I1y: 21,
    I2x: 33,
    I2y: 33,
  },
  {
    name: TableItemName.FIFTY,
    Force: 5,
    E1: 26,
    E2: 18,
    I3x: 9,
  },
  {
    name: TableItemName.SEVENTY_FIVE,
    Force: 5,
    E1: 26,
    E2: 18,
    I3x: 9,
  },
  {
    name: TableItemName.MAX,
    Force: 5,
    E1: 26,
    E2: 18,
    I3x: 9,
  },
];

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);

export const TensilTestSafetyCoefTable = () => {
  const {
    chunkValues,
    selectedValues,
    setSelectedValues,
    protocol,
    setSelectedInclinomer,
    selectedInclinomer,
  } = useTensilTests();

  const selectValue = useCallback(
    (index: number, key: keyof SelectedState) =>
      setSelectedValues(prev => {
        if (key.includes('inclino') && key !== selectedInclinomer) {
          return prev;
        }
        if (prev[key].length === 0) {
          return { ...prev, [key]: [chunkValues[index]] };
        }

        if (prev[key].some(f => f.number === chunkValues[index].number)) {
          return {
            ...prev,
            [key]: prev[key].filter(
              f => f.number !== chunkValues[index].number,
            ),
          };
        }
        return { ...prev, [key]: [...prev[key], chunkValues[index]] };
      }),
    [chunkValues, setSelectedValues, selectedInclinomer],
  );

  const selectInclino = (key: string) => {
    const selectedInclino = getSelectedInclino(key);

    if (selectedInclino) {
      setSelectedInclinomer(previousInclino => {
        if (previousInclino !== selectedInclino) {
          if (previousInclino && selectedValues[previousInclino]) {
            setSelectedValues(prevVals => ({
              ...prevVals,
              [previousInclino]: [],
            }));
          }

          return selectedInclino;
        }

        return previousInclino;
      });
    }
  };

  return (
    <Table size="small" padding="none">
      <TableHead style={{ padding: '10px 0' }}>
        <TableRow>
          <TableCell />
          {Object.keys(tableObject[0]).map(
            key =>
              key !== 'name' && (
                <TableCell
                  onClick={() => chunkValues.length > 0 && selectInclino(key)}
                  key={key}
                  align="center"
                  style={{
                    padding: 5,
                    backgroundColor:
                      selectedInclinomer &&
                        key === getSelectedInclinoShort(selectedInclinomer)
                        ? '#009577'
                        : 'transparent',
                    borderRadius: 8,
                  }}
                >
                  <Badge
                    style={{
                      backgroundColor: getMeterBgColor(key),
                    }}
                    className={css(
                      key === 'Force'
                        ? styles.force
                        : dynamicStyles(getMeterBgColor(key)).badge,
                    )}
                  >
                    {key}
                  </Badge>
                </TableCell>
              ),
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableObject.map((row: TableObjectItemProps, index) => {
          return (
            <StyledTableRow key={row.name}>
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                {row.name}
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'force100')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.force100.some(
                        f => f?.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {chunkValues?.[index]?.force100?.toFixed(TensilTestInputs.force100.precision) ?? 0}
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'elasto90')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.elasto90.some(
                        f => f?.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {(chunkValues?.[index]?.elasto90?.toFixed(TensilTestInputs.elasto90.precision) ?? 0)}
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'elasto91')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.elasto91.some(
                        f => f?.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {chunkValues?.[index]?.elasto91?.toFixed(TensilTestInputs.elasto91.precision) ?? 0}
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'inclino80x')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.inclino80x.some(
                        f => f?.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {chunkValues?.[index]?.inclino80x?.toFixed(TensilTestInputs.inclino80x.precision) ?? 0}
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'inclino80y')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.inclino80y.some(
                        f => f.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {chunkValues?.[index]?.inclino80y?.toFixed(TensilTestInputs.inclino80y.precision) ?? 0}
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'inclino81x')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.inclino81x.some(
                        f => f.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {chunkValues?.[index]?.inclino81x?.toFixed(TensilTestInputs.inclino81x.precision) ?? 0}
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell
                style={{ padding: 5 }}
                align="center"
                onClick={() =>
                  chunkValues.length > 0 && selectValue(index, 'inclino81y')
                }
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className={css(
                      selectedValues.inclino81y.some(
                        f => f?.number === chunkValues[index]?.number,
                      )
                        ? styles.selectedCell
                        : null,
                    )}
                  >
                    {chunkValues?.[index]?.inclino81y?.toFixed(TensilTestInputs.inclino81y.precision) ?? 0}
                  </Typography>
                </Grid>
              </TableCell>
            </StyledTableRow>
          );
        })}
        <StyledTableRow>
          <TableCell component="th" scope="row">
            protokol
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.force100?.toFixed(TensilTestInputs.force100.precision)}
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.elasto90?.toFixed(TensilTestInputs.elasto90.precision)}
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.elasto91?.toFixed(TensilTestInputs.elasto91.precision)}
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.inclino80x?.toFixed(TensilTestInputs.inclino80x.precision)}
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.inclino80y?.toFixed(TensilTestInputs.inclino80y.precision)}
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.inclino81x?.toFixed(TensilTestInputs.inclino81x.precision)}
          </TableCell>
          <TableCell style={{ padding: 5 }} align="center">
            {protocol?.inclino81y?.toFixed(TensilTestInputs.inclino81y.precision)}
          </TableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  );
};
