export enum ElastomerTag {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
}

export interface Elastomer {
  name: ElastomerTag;
  color: string;
}

export const ElastomerTagsConfig: {
  [key in ElastomerTag]: Elastomer;
} = {
  [ElastomerTag.E1]: { name: ElastomerTag.E1, color: '#2F80ED' },
  [ElastomerTag.E2]: { name: ElastomerTag.E2, color: '#F2994A' },
  [ElastomerTag.E3]: { name: ElastomerTag.E3, color: '#CF91F9' },
  [ElastomerTag.E4]: { name: ElastomerTag.E4, color: '#FF56C2' },
};

export enum InclinometerTag {
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  I4 = 'I4',
}

export interface Inclinometer {
  name: InclinometerTag;
  color: string;
}

export const InclinometerTagsConfig: {
  [key in InclinometerTag]: Inclinometer;
} = {
  [InclinometerTag.I1]: { name: InclinometerTag.I1, color: '#2F80ED' },
  [InclinometerTag.I2]: { name: InclinometerTag.I2, color: '#2F80ED' },
  [InclinometerTag.I3]: { name: InclinometerTag.I3, color: '#CF91F9' },
  [InclinometerTag.I4]: { name: InclinometerTag.I4, color: '#FF56C2' },
};

export enum BallastHeightLevelType {
  PULL = 'pull',
  PUSH = 'push',
}

export interface BallastHeightLevel {
  type: BallastHeightLevelType;
  height?: number;
  elestomer?: Elastomer;
  isActive: boolean;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Ballast {
  id: string;
  location: Location;
  isNewlyAdded?: boolean;
  elastomers?: BallastHeightLevel[];
  inclinometersHeight?: number;
  inclinometers?: (Inclinometer | undefined)[];
  anchorageHeight?: number;
  ropeAngle?: number;
  ktDistance?: number;
  note?: string;
}

export interface DirectionOfLoad {
  location: Location;
  inclinometers: Inclinometer[];
  elastomers: BallastHeightLevel[];
  note?: string;
  tensilTests?: TensilTest[];
  anchorageHeight?: number;
  ropeAngle?: number;
  ktDistance?: number;
}

export type InputData = {
  elasto90: number;
  elasto91: number;
  force100: number;
  inclino80x: number;
  inclino80y: number;
  inclino81x: number;
  inclino81y: number;
  number: number;
  ropeAndle100: number;
  timestamp: string;
  time: string;
};

export type InputDataKey = keyof InputData;

export type DirectionData = {
  elastomers: BallastHeightLevel[];
  inclinometers: Inclinometer[];
  isNewlyAdded: boolean;
  location: { latitude: number; longitude: number };
  uid: string;
  anchorageHeight: number;
  ropeAngle: number;
  ktDistance: number;
  barkThickness: number;
};
export interface TensilTest {
  id: number;
  uid: string;
  status: string;
  inputFileUrl: string;
  pdfUrl: string;
  directionData: DirectionData;
  data: {
    results: TCalculations;
    inputData: InputData[];
    calculationsInputs: {
      protocol: any;
      selectedValue: InputData;
      selectedMoments: SelectedState;
    };
  };
}

export interface Elastomer {
  name: ElastomerTag;
  color: string;
}

export interface BallastHeightLevel {
  type: BallastHeightLevelType;
  height?: number;
  elestomer?: Elastomer;
  isActive: boolean;
}

export type TensilTestsDTO = {
  taxon: string;
  id: number;
  uid: string;
  status: string;
  inputFileUrl: string;
  pdfUrl: string;
  directionData: DirectionData;
  data: {
    results: TCalculations;
    inputData: InputData[];
    calculationsInputs: {
      protocol: any;
      selectedValues: [InputData, InputData];
      selectedMoments: SelectedState;
    };
  };
};

export type SelectedState = {
  force100: InputData[];
  elasto90: InputData[];
  elasto91: InputData[];
  inclino80x: InputData[];
  inclino80y: InputData[];
  inclino81x: InputData[];
  inclino81y: InputData[];
};

export type TCalculation = {
  safetyFactor: number;
  ropeAngle: number;
  shearStrength: number;
  bendingMomentAtMeasure: number;
  momentOfInertia: number;
  shareOfWindLoad: number;
  relativeDeformation: number;
  measurementPosition: number;
  criticalBendingMoment: number;
  inclinometer: number;
  bendingMomentAtBase: number;
  substitutionForce: number;
  resistanceToDislocation: number;
  crossSectionModule: number;
  anchorageHeight: number;
  trunkAvg1: number;
  variableDeformation: number;
  crownExcentricity: number;
  trunkAvg2: number;
  elastometer: number;
  shearStress: number;
  resistanceToFracture: number;
  cleanDiameter: number;
  barkThickness: number;
  ktDistance: number;
  dynamometer: number;
};

export type TCalculations = {
  E1: TCalculation;
  E2: TCalculation;
};

export type Protocol = {
  force100: number;
  elasto90: number;
  elasto91: number;
} & (
  | Record<'inclino80x', number>
  | Record<'inclino80y', number>
  | Record<'inclino81x', number>
  | Record<'inclino81y', number>
);


export const UNITS = {
  DEGREES: '°',
  DIMENSIONLESS: '',
  PERCENTAGE: '%',
  KN: ' kN',
  KNCM2: 'kN/cm²',
  KNCM3: 'kN/cm³',
  KNM: 'kN⋅m',
  M: 'm',
  CM: 'cm',
  CM3: 'cm³',
  MICROM: 'µm',
} as const;

export type UNIT = typeof UNITS[keyof typeof UNITS];

export const CALCULATIONS: Record<string, TensilTestTypeDisplayConfig> = {
  measurementPosition:     { unit: UNITS.M,             precision: 2 },
  bendingMomentAtBase:     { unit: UNITS.KNM,           precision: 2 },
  bendingMomentAtMeasure:  { unit: UNITS.KNM,           precision: 2 },
  criticalBendingMoment:   { unit: UNITS.KNM,           precision: 2 },
  resistanceToFracture:    { unit: UNITS.PERCENTAGE,    precision: 2 },
  resistanceToDislocation: { unit: UNITS.PERCENTAGE,    precision: 2 },
  safetyFactor:            { unit: UNITS.PERCENTAGE,    precision: 2 },
  cleanDiameter:           { unit: UNITS.CM,            precision: 2 },
  crossSectionModule:      { unit: UNITS.KNCM3,         precision: 2 },
  crownExcentricity:       { unit: UNITS.M,             precision: 2 },
  dynamometer:             { unit: UNITS.KN,            precision: 2 },
  momentOfInertia:         { unit: UNITS.CM3,           precision: 2 },
  relativeDeformation:     { unit: UNITS.KNM,           precision: 5 },
  shareOfWindLoad:         { unit: UNITS.PERCENTAGE,    precision: 2 },
  shearStrength:           { unit: UNITS.KNCM2,         precision: 2 },
  shearStress:             { unit: UNITS.KNCM2,         precision: 2 },
  substitutionForce:       { unit: UNITS.KNM,           precision: 2 },
  variableDeformation:     { unit: UNITS.PERCENTAGE,    precision: 2 },
  trunkAvg1:               { unit: UNITS.CM,            precision: 2 },
  trunkAvg2:               { unit: UNITS.CM,            precision: 2 },
  E:                       { unit: UNITS.DIMENSIONLESS, precision: 2 },
} as const;

export type CALCULATION_ID = keyof typeof CALCULATIONS;

type TensilTestTypeDisplayConfig = {
  unit: UNIT;
  precision: number;
};

export const TensilTestInputs: Record<string, TensilTestTypeDisplayConfig> = {
  force100:   { unit: UNITS.KN,      precision: 2 },
  elasto90:   { unit: UNITS.MICROM,  precision: 0 },
  elasto91:   { unit: UNITS.MICROM,  precision: 0 },
  inclino80x: { unit: UNITS.DEGREES, precision: 3 },
  inclino80y: { unit: UNITS.DEGREES, precision: 3 },
  inclino81x: { unit: UNITS.DEGREES, precision: 3 },
  inclino81y: { unit: UNITS.DEGREES, precision: 3 },
} as const;


export const TensilTestDirectionData: Record<string, TensilTestTypeDisplayConfig> = {
  anchorageHeight: { unit: UNITS.M,       precision: 2 },
  ktDistance:      { unit: UNITS.M,       precision: 2 },
  ropeAngle:       { unit: UNITS.DEGREES, precision: 3 },
} as const;
