import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CategoricalChartState } from 'recharts/types/chart/types';

import {
  saveCaluculation,
  sendForCaluculation,
  sendForPrecaluculation,
} from '../helpers/apiHelper';
import { findClosestValue } from '../helpers/tensilTestsHelpers';
import {
  InputData,
  Protocol,
  SelectedState,
  TensilTestsDTO,
} from '../interfaces/tenstilTest';

export const useTensilTestCalculation = () => useMutation(sendForCaluculation);
export const useTensilTestPrecalculation = () =>
  useMutation(sendForPrecaluculation);
export const useTensilTestSaveCalculation = () => useMutation(saveCaluculation);

function* chunks<T extends any>(arr: T[], n: number) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

type SelectedInclino =
  | 'inclino80x'
  | 'inclino80y'
  | 'inclino81x'
  | 'inclino81y';

function getSelectedInclino(moments: SelectedState | null) {
  if (!moments) {
    return null;
  }

  let selectedInclino: SelectedInclino | null = null;

  const { elasto90, elasto91, force100, ...rest } = moments;
  Object.entries(rest).forEach(([key, data]) => {
    const typedKey = key as SelectedInclino;
    if (data?.length > 0) {
      selectedInclino = typedKey;
    }
  });

  return selectedInclino;
}

function useTensilTestsManager(tensilTests: TensilTestsDTO[]) {
  const {
    mutate: calculate,
    data: calcs,
    ...restCalculation
  } = useTensilTestCalculation();
  const {
    mutate: precalculate,
    data: precalcs,
    ...restPrecalculation
  } = useTensilTestPrecalculation();
  const {
    mutate: saveCalc,
    data: newTest,
    ...restSaveCalc
  } = useTensilTestSaveCalculation();
  const [activeTensilTest, setActiveTensilTest] = useState(tensilTests[0]);
  const [selectedInclinomer, setSelectedInclinomer] =
    useState<SelectedInclino | null>(
      getSelectedInclino(
        activeTensilTest?.data?.calculationsInputs?.selectedMoments,
      ),
    );

  const [selectingValue, setSelectingValue] = useState<'min' | 'max' | 'auto'>(
    'auto',
  );

  const [[min, max], setSelectedIndexes] = useState<[number, number]>([
    activeTensilTest.data.inputData.findIndex(
      d =>
        d.number ===
        activeTensilTest?.data?.calculationsInputs?.selectedValues[0]?.number,
    ),
    activeTensilTest.data.inputData.findIndex(
      d =>
        d.number ===
        activeTensilTest?.data?.calculationsInputs?.selectedValues[1]?.number,
    ),
  ]);

  const [selectedValues, setSelectedValues] = useState<SelectedState>({
    force100:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.force100 ||
      [],
    elasto90:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.elasto90 ||
      [],
    elasto91:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.elasto91 ||
      [],
    inclino80x:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.inclino80x ||
      [],
    inclino80y:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.inclino80y ||
      [],
    inclino81x:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.inclino81x ||
      [],
    inclino81y:
      activeTensilTest?.data?.calculationsInputs?.selectedMoments?.inclino81y ||
      [],
  });

  const form = useForm<{
    anchorageHeight: number;
    ropeAngle: number;
    ktDistance: number;
    taxon: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      anchorageHeight: activeTensilTest.directionData.anchorageHeight,
      ropeAngle: activeTensilTest.directionData.ropeAngle,
      ktDistance: activeTensilTest.directionData.ktDistance,
      taxon: activeTensilTest.taxon,
    },
  });

  const protocol: {
    force100: number;
    elasto90: number;
    elasto91: number;
    inclino80x?: number;
    inclino80y?: number;
    inclino81x?: number;
    inclino81y?: number;
  } = useMemo(() => {
    const prot = {
      force100:
        selectedValues.force100.length > 0
          ? +(
            selectedValues.force100
              .map(chunk => chunk.force100)
              .reduce((prev, current) => prev + current, 0) /
            selectedValues.force100.map(chunk => chunk.force100).length
          ).toFixed(3)
          : 0,
      elasto90:
        selectedValues.elasto90.length > 0
          ? +(
            selectedValues.elasto90
              .map(chunk => chunk.elasto90)
              .reduce((prev, current) => prev + current, 0) /
            selectedValues.elasto90.map(chunk => chunk.elasto90).length
          ).toFixed(3)
          : 0,
      elasto91:
        selectedValues.elasto91.length > 0
          ? +(
            selectedValues.elasto91
              .map(chunk => chunk.elasto91)
              .reduce((prev, current) => prev + current, 0) /
            selectedValues.elasto91.map(chunk => chunk.elasto91).length
          ).toFixed(3)
          : 0,
    };
    if (selectedInclinomer) {
      prot[selectedInclinomer] =
        selectedValues[selectedInclinomer].length > 0
          ? +selectedValues[selectedInclinomer]
            .map(chunk => chunk[selectedInclinomer])
            .reduce((prev, current) => prev + current, 0) /
          selectedValues[selectedInclinomer].map(
            chunk => chunk[selectedInclinomer],
          ).length
          : 0;
    }

    return prot;
  }, [selectedValues, selectedInclinomer]);

  const handlePaginationChange = useCallback(
    (newPage: number) => {
      const newActiveTensilTest = tensilTests[newPage - 1];
      setActiveTensilTest(newActiveTensilTest);
      setSelectedInclinomer(
        getSelectedInclino(
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments,
        ),
      );
      setSelectedIndexes([
        newActiveTensilTest.data.inputData.findIndex(
          d =>
            d.number ===
            newActiveTensilTest?.data?.calculationsInputs?.selectedValues[0]
              ?.number,
        ),
        newActiveTensilTest.data.inputData.findIndex(
          d =>
            d.number ===
            newActiveTensilTest?.data?.calculationsInputs?.selectedValues[1]
              ?.number,
        ),
      ]);
      setSelectedValues({
        force100:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.force100 || [],
        elasto90:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.elasto90 || [],
        elasto91:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.elasto91 || [],
        inclino80x:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.inclino80x || [],
        inclino80y:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.inclino80y || [],
        inclino81x:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.inclino81x || [],
        inclino81y:
          newActiveTensilTest?.data?.calculationsInputs?.selectedMoments
            ?.inclino81y || [],
      });

      form.reset({
        // @ts-ignore
        anchorageHeight:
          newActiveTensilTest?.directionData?.anchorageHeight || '',
        // @ts-ignore
        ropeAngle: newActiveTensilTest?.directionData?.ropeAngle || '',
        // @ts-ignore
        ktDistance: newActiveTensilTest?.directionData?.ktDistance || '',
        taxon: newActiveTensilTest?.taxon,
      });
    },
    [tensilTests, form],
  );

  const selectValue = useCallback(
    (e: CategoricalChartState) => {
      if (!e || !e?.activePayload || e?.activePayload.length === 0) {
        return;
      }
      const activePayload = e.activePayload[0];
      const newIndex = activeTensilTest.data.inputData.findIndex(
        v => v.number === activePayload?.payload?.number,
      );

      if (!selectingValue || selectingValue === 'auto') {
        setSelectedIndexes(([prevMin, prevMax]) => {
          if (newIndex === prevMin || newIndex === prevMax) {
            return [prevMin, prevMax];
          }
          if (prevMin < 0) {
            return [newIndex, prevMax];
          }
          if (prevMax < 0) {
            return [prevMin, newIndex];
          }

          if (newIndex < prevMax) {
            return [newIndex, prevMax];
          } else {
            return [prevMin, newIndex];
          }
        });
      } else if (selectingValue === 'min') {
        if (newIndex < max) {
          setSelectedIndexes(([_prevMin, prevMax]) => [newIndex, prevMax]);
        }
      } else {
        if (newIndex > min) {
          setSelectedIndexes(([prevMin, _prevMax]) => [prevMin, newIndex]);
        }
      }
    },
    [activeTensilTest.data.inputData, max, min, selectingValue],
  );

  const slicedData = useMemo(
    () =>
      !activeTensilTest?.data?.inputData ||
        activeTensilTest?.data?.inputData.length === 0 ||
        (min < 0 && max < 0)
        ? []
        : activeTensilTest.data.inputData.slice(
          min,
          max + 1 >= activeTensilTest?.data?.inputData.length
            ? activeTensilTest?.data?.inputData.length
            : max + 1,
        ),
    [activeTensilTest.data.inputData, max, min],
  );

  const chunkValues = useMemo(() => {
    if (
      !activeTensilTest?.data?.inputData ||
      activeTensilTest?.data?.inputData.length === 0 ||
      (min < 0 && max < 0)
    ) {
      return [];
    }
    const slicedChunks: InputData[][] = [
      // @ts-ignore
      ...chunks(slicedData, slicedData.length / 4),
    ];
    return slicedChunks
      .map(chunk => chunk[chunk.length - 1])
      .filter(Boolean)
      .map(item => {
        return Object.entries(item).reduce((prev, [key, value]) => {
          if (!value) {
            return {
              ...prev,
              [key]: findClosestValue(
                slicedData.map(d => d[key]),
                slicedData.findIndex(i => i.number === item.number),
              ),
            };
          }

          return { ...prev, [key]: value };
        }, {}) as InputData;
      });
  }, [activeTensilTest.data.inputData, max, min, slicedData]);

  const calculateDirection = useCallback(
    (values: {
      anchorageHeight: number;
      ropeAngle: number;
      ktDistance: number;
      taxon: string;
    }) => {
      if (!activeTensilTest || (min < 0 && max < 0)) {
        return;
      }

      const objToSend = {
        values,
        id: activeTensilTest.id,
        selectedValues: [
          activeTensilTest.data.inputData[min],
          activeTensilTest.data.inputData[max],
        ] as [InputData, InputData],
        selectedMoments: selectedValues,
        protocol: protocol as Protocol,
      };
      calculate(objToSend);
    },
    [activeTensilTest, min, max, selectedValues, protocol, calculate],
  );
  const precalculateDirection = useCallback(
    (values: {
      anchorageHeight: number;
      ropeAngle: number;
      ktDistance: number;
      taxon: string;
    }) => {
      if (!activeTensilTest || (min < 0 && max < 0) || !selectedInclinomer) {
        return;
      }

      const objToSend = {
        values,
        id: activeTensilTest.id,
        selectedValues: [
          activeTensilTest.data.inputData[min],
          activeTensilTest.data.inputData[max],
        ] as [InputData, InputData],
        selectedMoments: selectedValues,
        protocol: protocol as Protocol,
        selectedInclinomer,
        chunkValues,
      };
      precalculate(objToSend);
    },
    [
      activeTensilTest,
      min,
      max,
      selectedValues,
      protocol,
      chunkValues,
      precalculate,
      selectedInclinomer,
    ],
  );

  const saveDirection = useCallback(
    (values: {
      anchorageHeight: number;
      ropeAngle: number;
      ktDistance: number;
      taxon: string;
    }) => {
      if (!activeTensilTest || (min < 0 && max < 0)) {
        return;
      }

      const objToSend = {
        values,
        id: activeTensilTest.id,
        selectedValues: [
          activeTensilTest.data.inputData[min],
          activeTensilTest.data.inputData[max],
        ] as [InputData, InputData],
        selectedMoments: selectedValues,
        protocol: protocol as Protocol,
        chunkValues,
      };
      saveCalc(objToSend, {
        onSuccess: data => {
          if (data.pdfUrl) {
            const fileName =
              data.pdfUrl.split('/')[data.pdfUrl.split('/').length - 1];

            const link = document.createElement('a');
            link.href = data.pdfUrl;
            link.download = fileName;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        },
      });
    },
    [
      activeTensilTest,
      min,
      max,
      selectedValues,
      protocol,
      saveCalc,
      chunkValues,
    ],
  );

  const calculations = useMemo(() => {
    if (activeTensilTest.data.results) {
      return activeTensilTest.data.results;
    }

    return calcs;
  }, [activeTensilTest?.data?.results, calcs]);

  return {
    calculations,
    precalculations: precalcs,
    saveDirection,
    newTest,
    tensilTests,
    handlePaginationChange,
    activeTensilTest,
    min,
    max,
    selectValue,
    calculateDirection,
    chunkValues,
    setSelectedValues,
    selectedValues,
    protocol,
    precalculateDirection,
    setSelectedInclinomer,
    selectedInclinomer,
    form,
    restCalculation,
    restPrecalculation,
    restSaveCalc,
    selectingValue,
    setSelectingValue,
  };
}

type UseTensilTestManagerResult = ReturnType<typeof useTensilTestsManager>;

export const TensilTestsContext = createContext<UseTensilTestManagerResult>({
  tensilTests: [],
  handlePaginationChange: (_newPage: number) => { },
  // @ts-ignore
  activeTensilTest: {},
});

export const TensilTestsProvider: FC<{ tensilTests: TensilTestsDTO[] }> = ({
  children,
  tensilTests,
}) => {
  const value = useTensilTestsManager(tensilTests);
  return (
    <TensilTestsContext.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </TensilTestsContext.Provider>
  );
};

export const useTensilTests = () => useContext(TensilTestsContext);
