import { Row } from 'antd';
import { css } from 'aphrodite';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { TensilTestsProvider } from '../../../contexts/TensilTestsContext';
import { getRouteLink } from '../../../helpers/treeHelpers';
import { SiteTab } from '../../../interfaces/enums';
import { getActiveTreeById } from '../../../store/trees/selectors';
import { CalculationsTab } from '../../treeDetailPage/Calculations/CalculationsTab';
import { TensilTestTab } from '../../treeDetailPage/TensilTest';
import TomogramsTab from '../../treeDetailPage/Tomograms/TomogramsTab';
import { PhotoEditingPage } from '../PhotoEditingPage/PhotoEditingPage';
import TreeDetailPageForm from '../TreeDetailPageForm/TreeDetailPageForm';
import TrunkTreePage from '../TrunkTreePage/TrunkTreePage';
import WholeTreePage from '../WholeTreePage/WholeTreePage';
import { styles } from './TreeDetailRouter.styles';

const TreeDetailRouter: FC = () => {
  const activeTree = useSelector(getActiveTreeById);
  return (
    <Fragment>
      <Switch>
        <Route
          path={getRouteLink(':id', SiteTab.TreeTopAnalysis)}
          render={() => (
            <Row className={css(styles.body)}>
              <WholeTreePage />
            </Row>
          )}
          exact
        />
        <Route
          path={getRouteLink(':id', SiteTab.TrunkAnalysis)}
          render={() => (
            <Row className={css(styles.body)}>
              <TrunkTreePage />
            </Row>
          )}
          exact
        />
        <Route
          path={getRouteLink(':id', SiteTab.Photos)}
          render={() => (
            <Row className={css(styles.body)}>
              <PhotoEditingPage />
            </Row>
          )}
          exact
        />
        <Route
          path={getRouteLink(':id', SiteTab.TensilTest)}
          render={() =>
            activeTree ? (
              <TensilTestsProvider tensilTests={activeTree.tensilTests}>
                <TensilTestTab />
              </TensilTestsProvider>
            ) : null
          }
        />
        <Route
          path={getRouteLink(':id', SiteTab.Tomograms)}
          render={() => <TomogramsTab />}
        />
        <Route
          path={getRouteLink(':id', SiteTab.Calculations)}
          render={() => <CalculationsTab />}
        />
        <Route
          path={getRouteLink(':id')}
          render={() => (
            <Row className={css(styles.body)}>
              <TreeDetailPageForm />
            </Row>
          )}
          exact
        />
      </Switch>
    </Fragment>
  );
};

export default TreeDetailRouter;
