import { Grid } from '@material-ui/core';
import { AxiosError } from 'axios';
import { useTensilTests } from '../../contexts/TensilTestsContext';
import { colors } from '../../styles/colors';
import { useCurrentLanguage } from '../../translations/languageSelector';
import AppAlert from '../treeDetailPageNew/components/Form/AppAlert/AppAlert';
import { NoDataTab } from '../treeDetailPageNew/components/NoDataTab/NoDataTab';
import { TensilTestBody } from '../treeDetailPageNew/components/TensilTests/TensilTestBody';
import { TensilTestHeader } from '../treeDetailPageNew/components/TensilTests/TensilTestsHeader';
import './TensilTest.styles_legacy.css';

export const getRouteLink = (treeId: string | number, pageNumber: string) => {
  return `/treeDetail/${treeId}/tensilTest/${pageNumber}`;
};

export const TensilTestTab = () => {
  const activeLang = useCurrentLanguage();

  const {
    tensilTests,
    restPrecalculation: { error: precalculationError },
    restCalculation: { error: calculationError },
    restSaveCalc: {
      error: saveCalculationError,
      isLoading: isSaveCalculatioLoading,
    },
  } = useTensilTests();
  const precalculationAError = precalculationError as AxiosError | undefined;
  const calculationAError = calculationError as AxiosError | undefined;
  const saveCalculationAError = saveCalculationError as AxiosError | undefined;
  if (!tensilTests.length) {
    return <NoDataTab title={activeLang.treeDetailPage.tensilTest.noData} />;
  }

  return (
    <Grid container style={{ padding: 40 }}>
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSaveCalculatioLoading}
        message={activeLang.treeDetailPage.tensilTest.saving}
        severity="warning"
        autoHideDuration={10000}
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!saveCalculationAError}
        message={saveCalculationAError?.response?.data?.message}
        autoHideDuration={10000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!calculationAError}
        message={calculationAError?.response?.data?.message}
        autoHideDuration={10000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!precalculationAError}
        message={precalculationAError?.response?.data?.message}
        autoHideDuration={10000}
        color={colors.redPrimary}
        severity="error"
      />
      <TensilTestHeader />
      <TensilTestBody />
    </Grid>
  );
};
