export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const ARBO_CORE_API = process.env.REACT_APP_ARBO_CORE_API;

// google marker measures
export const MARKER_WIDTH = 30;
export const MARKER_HEIGHT = 140;
export const IMAGE_CROP_RATIO = 0.75;

export const COOKIE_NAME = 'NotumTreeScannerLoggedUser';

// max number of DHB input boxes on form
export const NO_DBH_INPUTS_BOXES = 4;

// CANVAS:
// max number of accepted points to create crown calculations
export const CANVAS_BASE_POINTS = 2;
export const CANVAS_CROWN_POINTS = 3;
export const CANVAS_TRUNK_POINTS = 4;
// max number of accepted points to create scale
export const CANVAS_SCALE_POINTS = 2;
// scale constant for grating grid line in 1.3m
export const GRID_CONSTANT_1_3 = 1.3;
// distance between clicked and generated point
export const POINT_COUPLE_DISTANCE = 100;
// canvas width coefficient measured to the whole screen width
export const CANVAS_WIDTH_TO_WINDOW_COEF = 0.44;
// canvas height coefficient -- used when calculated canvas height is bigger than window.innerHeight
export const CANVAS_HEIGHT_TO_WINDOW_COEF = 0.72;
// position of text over orientation gird canvas line
export const GRID_LINE_HINT_TEXT = { x: 3, y: -16 };
// const for header table of Operators
